import catchesBannerDesktop from '../assets/featureSections/catchesBannerDesktop.jpg';
import catchesBannerMobile from '../assets/featureSections/catchesBannerMobile.jpg';
import catchesBannerTablet from '../assets/featureSections/catchesBannerTablet.jpg';
import catchesPhone from '../assets/featureSections/catchesPhone.png';
import regsBannerDesktop from '../assets/featureSections/regsBannerDesktop.jpg';
import regsBannerMobile from '../assets/featureSections/regsBannerMobile.jpg';
import regsBannerTablet from '../assets/featureSections/regsBannerTablet.jpg';
import regsPhone from '../assets/featureSections/regsPhone.png';
import reportBannerDesktop from '../assets/featureSections/reportBGdesktop.png';
import reportBannerMobile from '../assets/featureSections/reportBGmobile.png';
import reportBannerTablet from '../assets/featureSections/reportBGtablet.png';
import reportsPhone from '../assets/featureSections/reportsPhone.png';
import spotBannerDesktop from '../assets/featureSections/spotBannerDesktop.jpg';
import spotBannerMobile from '../assets/featureSections/spotBannerMobile.jpg';
import spotBannerTablet from '../assets/featureSections/spotBannerTablet.jpg';
import spotsPhone from '../assets/featureSections/spotsPhone.png';
import { HomePageFeatureSectionProps } from '../components/HomePageFeatureSection/HomePageFeatureSection';

export const proSections: Array<HomePageFeatureSectionProps> = [
  {
    heading: 'Find the best spot',
    description:
      'Fishbrain gives you access to 14 million catch locations, Navionics high-resolution depth charts, and AI-powered fishing spot prediction to help you in your search for the best spots.',
    bannerImgMobile: spotBannerMobile,
    bannerImgTablet: spotBannerTablet,
    bannerImgDesktop: spotBannerDesktop,
    bannerImgAltText: 'Underwater',
    phoneImg: spotsPhone,
    phoneImageAlt: 'Explore depth maps on the Fishbrain app',
    phoneRight: false,
    linkText: 'Read more about features',
    linkRoute: '/features/fishing-spots',
    eventName: 'spot-prediction',
  },
  {
    heading: 'Local fishing reports',
    description:
      'Be prepared for your next fishing trip with intel on weather conditions, and, most importantly, what bait anglers use to catch specific species.',
    bannerImgMobile: reportBannerMobile,
    bannerImgTablet: reportBannerTablet,
    bannerImgDesktop: reportBannerDesktop,
    bannerImgAltText: '',
    phoneImg: reportsPhone,
    phoneImageAlt: '',
    phoneRight: true,
    linkText: undefined,
    linkRoute: undefined,
    appStoreLinks: true,
    eventName: 'local-fishing-reports',
  },
  {
    heading: 'Fishing regulations',
    description:
      'Be sure you can legally fish in a certain area, and know how to comply with all the local regulations to fish in a sustainable way and never get a ticket. Fishbrain regulations provide accurate and up-to-date knowledge about open seasons, bag limits, measurement rules, and more.',
    bannerImgMobile: regsBannerMobile,
    bannerImgTablet: regsBannerTablet,
    bannerImgDesktop: regsBannerDesktop,
    bannerImgAltText: '',

    phoneImg: regsPhone,
    phoneImageAlt: '',
    phoneRight: false,
    linkText: 'Find fishing regulations near you',
    linkRoute: '/explore?fib-ex-dv=regulations',
    eventName: 'regulations',
  },
  {
    heading: 'Log your catches',
    description:
      'Keep all of your catches organized in your own logbook. Upload your catch photos in bulk, and Fishbrain species recognition will identify each fish for you. The logbook can also fetch weather conditions from your catches, and provide useful insights and statistics.',
    bannerImgMobile: catchesBannerMobile,
    bannerImgTablet: catchesBannerTablet,
    bannerImgDesktop: catchesBannerDesktop,
    bannerImgAltText: '',
    phoneImg: catchesPhone,
    phoneImageAlt: '',
    phoneRight: true,
    linkText: 'Read more about features',
    linkRoute: '/features/logbook',
    eventName: 'logbook',
  },
];
