import React from 'react';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  gapType?: 'both' | 'x' | 'y' | 'custom';
}

const GAP_TYPE_CLASS = {
  both: 'gap-1',
  x: 'gap-x-3 sm:gap-x-4',
  y: 'gap-y-3 sm:gap-y-4',
  custom: '',
};

export const Grid = ({ gapType = 'both', className = '', ...rest }: IProps): JSX.Element => (
  <div
    className={`${className} grid grid-cols-4 ${GAP_TYPE_CLASS[gapType]} sm:grid-cols-12`}
    {...rest}
  />
);
