import React from 'react';

import { Accordion } from '../Accordion/Accordion';
import { ExternalLink } from '../Clickables/ExternalLink/ExternalLink';
import { Container } from '../Container/Container';
import { Heading } from '../Heading/Heading';

import { faqData } from './constant';

export const FAQ = (): JSX.Element => (
  <Container className="py-8" data-testid="FAQSection">
    <div className="sm:w-col-10 md:w-col-6 sm:mx-auto">
      <Heading variant="xl" className="mb-7 text-center">
        FAQ
      </Heading>
      <Accordion blocksData={faqData} className="mb-2 bg-lake-fog" labelClassName="pt-0" />
      <div className="mt-4 text-center text-lg">
        <ExternalLink
          variant="buttonTertiary"
          href="https://fishbrain.helpshift.com/hc/en/3-fishbrain---social-fishing-forecast-app/"
        >
          More FAQ
        </ExternalLink>
      </div>
    </div>
  </Container>
);
